import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`
  query ContactQuery {
    strapiContactPage {
      strapiId
      title
      subTitle
      description
      correspondenceTitle
      correspondenceDescription
      schoolTitle
      schoolDescription
      phoneTitle
      phoneNumber
      emailTitle
      emailAddress
      mapsCoordinates
      contactForm
    }
  }
`

const ContactPage = ({ data }) => {
  useEffect(() => {
    let googleMaps = document.createElement("script")
    googleMaps.src = "/js/_1_google-maps.js" // 👈 make sure to use the correct path
    googleMaps.id = "google-maps"
    googleMaps.async = false
    document.body.appendChild(googleMaps)

    let maps = document.createElement("script")
    maps.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdu81W3IYC704_eDlhHubiLU1RC9WdJEM&callback=initGoogleMap"
    maps.async = false
    document.body.appendChild(maps)

    let formElements = document.createElement("script")
    formElements.src = "/js/application.js"
    document.body.appendChild(formElements)

    return () => {
      document.body.removeChild(googleMaps)
      document.body.removeChild(maps)
      document.body.removeChild(formElements)
    }
  }, [])

  const contactPage = data.strapiContactPage

  return (
    <>
      <Layout>
        <div className="container max-width-adaptive-lg padding-top-xl margin-bottom-lg">
          <h1 className="text-xxxl text-center">{contactPage.title}</h1>
        </div>

        <section id="contact-us" className="contact-v2 margin-bottom-xxl">
          {/* <div className="container max-width-xs margin-bottom-lg">
                        <div className="text-component text-center">
                            <h2>{contactPage.subTitle}</h2>
                            <p>{contactPage.description}</p>
                        </div>
                    </div> */}

          <div className="container max-width-lg">
            <div className="margin-bottom-lg">
              <dl className="details-list details-list--rows grid details-list--cols@md">
                <div className="details-list__item padding-y-md padding-x-md@md text-center@md col-3@md">
                  <dt className="font-bold margin-bottom-xxs">
                    {contactPage.correspondenceTitle}
                  </dt>
                  <dd className="line-height-md">
                    {contactPage.correspondenceDescription}
                  </dd>
                </div>

                <div className="details-list__item padding-y-md padding-x-md@md text-center@md col-3@md">
                  <dt className="font-bold margin-bottom-xxs">
                    {contactPage.schoolTitle}
                  </dt>
                  <dd>{contactPage.schoolDescription}</dd>
                </div>

                <div className="details-list__item padding-y-md padding-x-md@md text-center@md col-3@md">
                  <dt className="font-bold margin-bottom-xxs">
                    {contactPage.phoneTitle}
                  </dt>
                  <dd className="line-height-md">
                    <a href={"tel:" + contactPage.phoneNumber}>
                      {contactPage.phoneNumber}
                    </a>
                  </dd>
                </div>
                <div className="details-list__item padding-y-md padding-x-md@md text-center@md col-3@md">
                  <dt className="font-bold margin-bottom-xxs">
                    {contactPage.emailTitle}
                  </dt>
                  <dd className="line-height-md">
                    <a href={"mailto:" + contactPage.emailAddress}>
                      {contactPage.emailAddress}
                    </a>
                  </dd>
                </div>
              </dl>
            </div>

            <div
              role="application"
              className="google-maps google-maps--ratio-3:1 js-google-maps margin-bottom-lg"
              data-coordinates={contactPage.mapsCoordinates}
            ></div>

            <div className="container max-width-md">
              <div className="bg-contrast-lower">
                <div
                  className="freedback"
                  dangerouslySetInnerHTML={{ __html: contactPage.contactForm }}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default ContactPage
